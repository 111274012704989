
















import { Component, Vue } from "vue-property-decorator";
import CaseItem from "./components/case-item.vue";
import vFreeApply from "@/components/v-free-apply.vue";
import { getCases } from "../../api";

@Component({
  components: { CaseItem, vFreeApply },
})
export default class extends Vue {
  private pageNo = 1;
  private totalPage = 1;
  private list = [];

  async getCases() {
    if (this.pageNo <= this.totalPage) {
      const res = await getCases({ pageNo: this.pageNo });
      let records = res.records;
      records = records.map((ele: any) => {
        ele.picUrl = ele.picUrl.replace("http://www.xmheigu.com", "");
        return ele;
      });
      this.list = this.list.concat(records);
      this.totalPage = res.pages;
      this.pageNo = this.pageNo + 1;
    }
  }

  mounted() {
    this.getCases();
  }
}
