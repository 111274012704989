







import { Component, Vue, Prop } from 'vue-property-decorator'
import VVideoModule from '@/components/v-video-module.vue'

@Component({
  components: { VVideoModule }
})
export default class extends Vue{
  @Prop() data!:any

  get videoData(){
    return {
      poster: this.data.picUrl,
      src: this.data.videoUrl,
      title: this.data.title,
      dec: this.data.description
    }
  }
}


